/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Move function visibility
 */
export enum MoveFunctionVisibility {
    PRIVATE = 'private',
    PUBLIC = 'public',
    FRIEND = 'friend',
}
